import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { captionSelectedImage } from "../store/atom";
import { useRecoilState } from "recoil";
import { captionImage } from "../apis/captionImage";
import DownloadContent from "../common/DownloadContent";
import ContentList from "../common/ContentList";
import { getLatestGeneratedCaptions } from "../apis/captionImage";

const CaptionImage: React.FC = () => {
  const [selectedImageData, setSelectedImageData] =
    useRecoilState(captionSelectedImage);
  const [loading, setLoading] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File>();
  const [captionedImageUrl, setCaptionedImageUrl] = useState<string | null>(
    null
  );
  const [generateError, setGenerateError] = useState<boolean>(false);
  const [DataMissingError, setDataMissingError] = useState<boolean>(false);

  const { data = [], isLoading } = useQuery(
    "getLatestGeneratedCaptions",
    getLatestGeneratedCaptions,
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (files) => {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setFile(file);
      setImageUrl(url);
      setSelectedImageData({
        ...selectedImageData,
        imageUrl: url
      });
    }
  });

  const handleGenerateImage = async () => {
    try {
      setLoading(true);
      if (file && selectedImageData) {
        const response = await captionImage(selectedImageData, file);
        if (!response || !response.data) {
          setCaptionedImageUrl(null);
          setGenerateError(true);
        } else {
          setCaptionedImageUrl(response.data);
          setGenerateError(false);
        }
      } else {
        setDataMissingError(true);
      }
    } catch (error) {
      setCaptionedImageUrl(null);
      setGenerateError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        color: "white",
        padding: "0px 16px"
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            {...getRootProps()}
            sx={{
              height: 40,
              p: 2,
              borderRadius: 2,
              width: "auto",
              border: "2px dashed #ffe263",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2
            }}
          >
            <input {...getInputProps()} />
            <Typography width={"100%"}>
              Click or drop an image or a gif file here
            </Typography>
            <CloudUploadIcon sx={{ color: "#ffe263", margin: 2 }} />
          </Box>
          <Box
            height="auto"
            width={"auto"}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                border: "1px solid white",
                borderRadius: 2,
                mb: 2
              }
            }}
          >
            {imageUrl && <img src={imageUrl} alt={imageUrl} />}
          </Box>
          <TextField
            variant="filled"
            fullWidth
            label="Uptext"
            value={selectedImageData.upText}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              border: "1px solid #ffe263",
              borderRadius: 1,
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ffe263"
                },
                "&:hover fieldset": {
                  borderColor: "white"
                }
              }
            }}
            onChange={(e) =>
              setSelectedImageData({
                ...selectedImageData,
                upText: e.target.value
              })
            }
            inputProps={{ style: { color: "white" } }}
          />
          <TextField
            variant="filled"
            fullWidth
            label="Downtext"
            value={selectedImageData.downText}
            InputLabelProps={{ style: { color: "white" } }}
            onChange={(e) =>
              setSelectedImageData({
                ...selectedImageData,
                downText: e.target.value
              })
            }
            sx={{
              border: "1px solid #ffe263",
              borderRadius: 1,
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ffe263"
                },
                "&:hover fieldset": {
                  borderColor: "white"
                }
              }
            }}
            inputProps={{ style: { color: "white" } }}
          />
          <Button
            variant="contained"
            onClick={handleGenerateImage}
            disabled={loading}
            sx={{
              width: "100%",
              backgroundColor: "#ffe263",
              "&:hover": {
                backgroundColor: "#ffb703"
              }
            }}
          >
            {loading ? "Generating..." : "Generate Image"}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              border: "1px solid #ffe263",
              padding: "8px",
              borderRadius: 2,
              textAlign: "center"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "200px"
              }}
            >
              {!captionedImageUrl &&
                !loading &&
                !generateError &&
                !DataMissingError && (
                  <Typography>
                    Your Captioned Image will display here
                  </Typography>
                )}
              {generateError && !loading && (
                <Container
                  sx={{
                    color: "red",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Typography variant="h6">
                    Couldn't generate image, please try again...
                  </Typography>
                </Container>
              )}
              {DataMissingError &&
                !generateError &&
                !loading &&
                !captionedImageUrl && (
                  <Container
                    sx={{
                      color: "red",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Typography variant="h6">
                      Please try again by uploading image and providing
                      captions.
                    </Typography>
                  </Container>
                )}
              {loading && (
                <Container
                  component="main"
                  maxWidth="xl"
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80%"
                  }}
                >
                  <CircularProgress />
                </Container>
              )}
              {captionedImageUrl && !loading && (
                <Box
                  sx={({ breakpoints }) => ({
                    background: `url("${captionedImageUrl}")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    [breakpoints.down("sm")]: {
                      height: "300px",
                      width: "300px"
                    },
                    [breakpoints.up("sm")]: {
                      height: "450px",
                      width: "450px"
                    }
                  })}
                >
                  <Box
                    sx={{
                      m: "8px 0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <DownloadContent
                      contentName={selectedImageData.upText}
                      contentUrl={captionedImageUrl}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <ContentList data={data} isLoading={isLoading} type={"caption"} />
      </Grid>
    </Box>
  );
};

export default CaptionImage;
