import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grid,
  Modal,
  Typography,
  TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState } from "recoil";
import { boxValuesState } from "../store/atom";
import { captionTemplateImage } from "../apis/imageTemplates";
import DownloadContent from "../common/DownloadContent";

interface OpenMemeTemplateProps {
  open: boolean;
  template: any;
  onClose: () => void;
}

const OpenMemeTemplate: React.FC<OpenMemeTemplateProps> = ({
  open,
  template,
  onClose
}) => {
  const [boxValues, setBoxValues] = useRecoilState(boxValuesState);
  const [loading, setLoading] = useState<boolean>(false);
  const [res, setRes] = useState<string | null>(null);
  const [generateError, setGenerateError] = useState<boolean>(false);
  const [DataMissingError, setDataMissingError] = useState<boolean>(false);

  const handleBoxChange = (index: number, value: string) => {
    if (value) {
      setBoxValues((prevBoxValues) => ({
        ...prevBoxValues,
        [`text${index}`]: value,
        imageId: template.id
      }));
    } else {
      setDataMissingError(true);
    }
  };

  if (!template) {
    return null;
  }

  const handleGenerateMemeTemplate = async () => {
    try {
      setLoading(true);
      if (boxValues) {
        const response = await captionTemplateImage(boxValues);
        if (!response || !response.data) {
          setRes(null);
          setGenerateError(true);
          setLoading(false);
        } else {
          setRes(response.data);
          setGenerateError(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Failed to generate meme template:", Error);
      setRes(null);
      setGenerateError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setGenerateError(false);
        setRes(null);
      }}
    >
      <Box
        sx={({ breakpoints }) => ({
          position: "absolute",
          color: "white",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#36454F",
          boxShadow: 24,
          p: 4,
          width: "auto",
          maxWidth: "60%",
          [breakpoints.down("sm")]: {
            width: "100%"
          },
          height: "auto",
          maxHeight: "80%",
          overflow: "auto"
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <CloseIcon
              sx={{
                fontSize: 30,
                cursor: "pointer",
                "&:hover": {
                  color: "red"
                }
              }}
              onClick={() => {
                onClose();
                setGenerateError(false);
                setBoxValues({ imageId: "" });
                setRes(null);
              }}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography variant="h6" mb={1}>
              Caption
            </Typography>
            {Array.from({ length: template.box_count }).map((_, index) => (
              <TextField
                key={index}
                label={`Box ${index + 1}...`}
                fullWidth
                InputLabelProps={{ style: { color: "#ffe263" } }}
                sx={{
                  mb: 2,
                  height: "auto",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#ffe263"
                    },
                    "&:hover fieldset": {
                      borderColor: "white"
                    }
                  }
                }}
                inputProps={{ style: { color: "white" } }}
                value={((boxValues as any)[`text${index}`] as any) || ""}
                onChange={(e) => handleBoxChange(index, e.target.value)}
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateMemeTemplate}
              disabled={loading}
              sx={{
                width: "100%",
                color: "#225093",
                backgroundColor: "#ffe263",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#225093"
                }
              }}
            >
              {loading ? "Generating..." : "Generate Image"}
            </Button>
          </Grid>

          <Grid item xs={12} md={7}>
            {res && !generateError && !loading && (
              <Box
                maxHeight="100%"
                width="100%"
                sx={{
                  overflow: "auto",
                  "& img": {
                    maxWidth: "100%",
                    maxHeight: 400,
                    objectFit: "contain"
                  }
                }}
              >
                <Typography variant="h6" mb={1}>
                  Result:
                </Typography>
                <div style={{ position: "relative" }}>
                  <div
                    style={{ position: "absolute", right: "8px", top: "8px" }}
                  >
                    <DownloadContent
                      contentName={template.name}
                      contentUrl={res}
                    />
                  </div>
                  <img src={res} alt="memegram" />
                </div>
              </Box>
            )}
            {!res && !generateError && !loading && (
              <Box
                maxHeight={"100%"}
                width="100%"
                sx={{
                  overflow: "auto",
                  "& img": {
                    maxWidth: "100%",
                    maxHeight: 400,
                    objectFit: "contain"
                  }
                }}
              >
                <Typography variant="h6" mb={1}>
                  Template
                </Typography>
                <img src={template.url} alt="memegram" />
              </Box>
            )}
            {!DataMissingError && generateError && (
              <Container
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red"
                }}
              >
                <Typography variant="h6">
                  Please try again by providing captions
                </Typography>
              </Container>
            )}
            {generateError && !loading && DataMissingError && (
              <Container
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "red"
                }}
              >
                <Typography variant="h6">
                  Couldn't generate image, please try again.
                </Typography>
              </Container>
            )}
            {loading && (
              <Container
                component="main"
                maxWidth="xl"
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80%"
                }}
              >
                <CircularProgress />
              </Container>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default OpenMemeTemplate;
