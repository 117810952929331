import axios from "axios";

import { API_ROOT } from "./utils";

export const searchContents = async (
  searchValue: string,
  type: "template" | "meme" | "caption" | "image"
) => {
  try {
    const response = await axios.get(
      `${API_ROOT}/api/contents?type=${type}&filter=${searchValue}`
    );
    return response.data;
  } catch (error) {
    console.error("searchContents error:", error);
    throw error;
  }
};
