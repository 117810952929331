import axios from "axios";
import { API_ROOT } from "./utils";

export const generateAiMeme = async (prompt: string) => {
  try {
    const response = await axios.post(`${API_ROOT}/api/memes`, {
      prompt: prompt
    });
    return response.data;
  } catch (error) {
    console.error("generate images error:", error);
    throw error;
  }
};

export const getAiMeme = async () => {
  try {
    const response = await axios.get(`${API_ROOT}/api/contents/?type=meme`);
    return response.data.contents;
  } catch (error) {
    console.error("generate images error:", error);
    throw error;
  }
};
