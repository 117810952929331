import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { useSearchParams, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid, Typography, Tabs, Tab, Button } from '@mui/material';
import Search from '../common/search';
import { searchContents } from '../apis/searchContents';
import { searchAtom } from '../store/search';
// @ts-ignore
import { Link } from 'react-router-dom';
import ContentList from '../common/ContentList';

type Content = 'image' | 'template' | 'meme' | 'caption';

const SearchContent: React.FC = () => {
  const [tabValue, setTabValue] = useState<Content>('image');
  const [searchValue, setSearchValue] = useRecoilState(searchAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = useLocation().search;

  const searchKeyword = new URLSearchParams(search)?.get('keyword');

  useEffect(() => {
    if (searchParams.has('keyword')) {
      searchParams.delete('keyword');
      setSearchParams(searchParams);
    }

    if (searchKeyword !== searchValue.value && searchKeyword) {
      setSearchValue({ value: searchKeyword });
      localStorage.setItem('search', searchKeyword);
    }
  }, []);

  const {
    data = {},
    isLoading,
    isFetching,
  } = useQuery(
    `searchContents-${tabValue}`,
    () => searchContents(searchValue.value, tabValue),
    {
      enabled: !!searchValue.value,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleTabChange = (value: Content) => {
    setTabValue(value);
  };

  const { contents = [], count = 0 } = data;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          color: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Search />
        </Box>
        <Box sx={{ margin: '16px 0px' }}>
          <Tabs
            value={tabValue}
            onChange={(_e, value) => handleTabChange(value)}
            aria-label="search contents tabs"
            sx={{
              '.MuiTab-root': {
                color: '#ffffffcc',
                padding: {
                  xs: '0px',
                  sm: '16px',
                },
                fontSize: {
                  xs: '11px',
                  sm: '14px',
                },
              },
              '.Mui-selected': {
                color: '#ffe263 !important',
                padding: {
                  xs: '0px',
                  sm: '16px',
                },
                fontSize: {
                  xs: '11px',
                  sm: '14px',
                },
              },
            }}
          >
            <Tab label="Images" value={'image'} />
            <Tab label="Templates" value={'template'} />
            <Tab label="Captions" value={'caption'} />
            <Tab label="Memes" value={'meme'} />
          </Tabs>
        </Box>
        {isLoading || isFetching ? (
          <Box
            sx={{
              margin: '0px 16px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ margin: '0px 16px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {searchValue.value ? (
                  <Typography variant="h6">
                    {/* Showing latest{' '}
                    <span style={{ color: '#ffe263' }}>{contents.length}</span>{' '}
                    /<b> {count}</b> contents */}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    <b>
                      <p>Search value is needed</p>
                    </b>
                    Search works with Telegram user Id or chat Id. Please
                    hit&nbsp;
                    <code style={{ color: 'red' }}>/id</code> in your telegram
                    group, then insert in the search bar and click "Search"
                    button.
                    <br />
                    <div style={{ opacity: 0.8 }}>
                      <b>Note: </b>
                      You can insert your own user id as well. Go to DM of the
                      bot and click <code style={{ color: 'red' }}>/id</code>
                    </div>
                    <Link to="https://t.me/memegramAIBot" target="_blank">
                      <Button
                        variant="outlined"
                        sx={{
                          marginTop: '16px',
                          textTransform: 'none',
                          fontWeight: 700,
                          border: '1px solid #ffe263',
                          color: '#ffe263',
                        }}
                        size="small"
                      >
                        Telegram Bot 🤖
                      </Button>
                    </Link>
                  </Typography>
                )}
              </Grid>
              <ContentList
                data={contents}
                isLoading={isLoading}
                type={tabValue}
              />
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SearchContent;
