import React, { useState } from "react";
import { useRecoilState } from "recoil";
import InputAdornment from "@mui/material/InputAdornment";
import { Box, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import { searchAtom } from "../store/search";

const Search: React.FC = () => {
  const [searchValue, setSearchValue] = useRecoilState(searchAtom);
  const [localSearchValue, setLocalSearchValue] = useState(searchValue.value);

  const handleSearchChange = (value: string) => {
    setLocalSearchValue(value);
  };

  const handleSearchSubmit = () => {
    localStorage.setItem("search", localSearchValue);
    setSearchValue({
      value: localSearchValue
    });
  };

  const handleClearSearch = () => {
    setLocalSearchValue("");
    setSearchValue({
      value: ""
    });
    localStorage.setItem("search", "");
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", margin: "8px 8px 0px 8px" }}
    >
      <TextField
        variant="outlined"
        placeholder="Filter by telegram id"
        size="small"
        name="prompt"
        value={localSearchValue || searchValue.value}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSearchChange(e.target.value);
        }}
        InputLabelProps={{ style: { color: "white" } }}
        sx={{
          display: "flex",
          width: {
            xs: "65vw",
            sm: "300px"
          },
          borderRadius: 1,
          background: "#36454f",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "1px solid #ffe263",
              borderRadius: "0px"
            },
            "&:hover fieldset": {
              border: "1px solid #ffe263",
              borderRadius: "0px"
            }
          }
        }}
        inputProps={{ style: { color: "white" } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" variant="filled">
              {searchValue ? (
                <CloseIcon
                  htmlColor="#ffe263"
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                    fontWeight: 500
                  }}
                  onClick={() => handleClearSearch()}
                />
              ) : (
                <SearchIcon
                  htmlColor="#ffe263"
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                    fontWeight: 500
                  }}
                />
              )}
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="outlined"
        disableRipple
        disableFocusRipple
        disableElevation
        sx={{
          backgroundColor: "#ffe263",
          "&:hover": {
            backgroundColor: "#ffe263"
          },
          border: "none !important",
          borderRadius: "0px",
          paddingLeft: "2px",
          paddingRight: "2px",
          color: "#225093",
          textTransform: "none"
        }}
        size="large"
        onClick={() => handleSearchSubmit()}
      >
        Search
      </Button>
    </Box>
  );
};

export default Search;
