// @ts-ignore

import { truncate } from 'lodash';

export const API_ROOT = `https://memegram-backend-33fa4e6c05a6.herokuapp.com`;

export const downloadContent = async (imageUrl: string, imageName: string) => {
  try {
    const response = await fetch(
      `${imageUrl}?cachebuster=${new Date().getTime()}`,
      {
        method: 'GET',
        mode: 'cors', // Ensures CORS is handled
        cache: 'no-cache', // Prevents caching issues
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch the file');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = truncate(imageName, {
      length: 30,
      separator: '',
    });
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    //
  }
};
