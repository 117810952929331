import { Box, Button, Typography, Grid, Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useState } from "react";
import { useQuery } from "react-query";
import { fetchImages } from "../apis/imageTemplates";
import OpenMemeTemplate from "./memeModal";

const MemeTemplate: React.FC = () => {
  const { data = [], isLoading } = useQuery("templates", fetchImages, {
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false
  });
  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleOpen = (template: any) => {
    setOpenTemplate(true);
    setSelectedTemplate(template);
  };

  const handleClose = () => {
    setOpenTemplate(false);
    setSelectedTemplate(null);
  };

  if (isLoading) {
    return (
      <Container
        component="main"
        maxWidth="xl"
        sx={{
          height: "100vh",
          width: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: "0px 16px"
      }}
    >
      <Grid container spacing={2}>
        {data.length === 0 && (
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: "center",
                color: "#ffe263"
              }}
            >
              Data is not available
            </Typography>
          </Grid>
        )}
        {data.map((template: any, index: number) => (
          <Grid item key={index} xs={6} sm={4} md={3} lg={2} xl={2}>
            <Box
              height={200}
              width={"100%"}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ffe263",

                "& img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                },
                "& button": {
                  top: "40%",
                  opacity: 0,
                  transition: "opacity 0.3s"
                },
                "&:hover button": {
                  opacity: 1
                }
              }}
            >
              <img src={template.url} alt={template.url} />
              <Button
                sx={{
                  position: "absolute",
                  top: "50%",
                  width: "auto",
                  height: "15%",
                  borderRadius: 5,
                  color: "#ffe263",
                  backgroundColor: "#225093",
                  boxShadow: 10,
                  "&:hover": {
                    backgroundColor: "#ffe263",
                    color: "white",
                    opacity: 1
                  },
                  textTransform: "none"
                }}
                variant="contained"
                onClick={() => handleOpen(template)}
              >
                Add Caption
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>

      <OpenMemeTemplate
        open={openTemplate}
        onClose={handleClose}
        template={selectedTemplate}
      />
    </Box>
  );
};

export default MemeTemplate;
