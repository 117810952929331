import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import MemeTemplate from './generateMeme/memeTemplate';
import { AiImage } from './AiImage/AiImage';
// @ts-ignore
import memegram from './assets/images/MGM1.png';
// @ts-ignore
import memegramTextLogo from './assets/images/memegramTextLogo.png';
import GenerateAiMeme from './generateAiMeme/generateAiMeme';
import CaptionImage from './CaptionImage/CaptionImage';
import Search from './SearchContent';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PageHeader from './common/pageHeader';

const drawerWidth = 230;

const extractLastPart = (url: string) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

const App: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeMenu, setActivemenu] = React.useState(
    `/${extractLastPart(document.location.href)}`,
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      component="nav"
      sx={{
        width: drawerWidth,
        backgroundColor: '#225093',
        backgroundImage:
          'url("https://assets-global.website-files.com/660a70bef8df59b086b785de/6620219940bfe699d52ccb5e_Memegram%20Web%20Hero-p-800.png")',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100px',
      }}
    >
      <Toolbar />
      <List>
        {[
          { text: 'Templates', link: '/' },
          { text: 'AI Meme', link: '/generate-meme' },
          { text: 'Add caption', link: '/caption' },
          { text: 'AI Image', link: '/ai-image' },
          { text: 'Search', link: '/search' },
        ].map(({ text, link }) => (
          <div key={text}>
            <ListItem key={text} onClick={() => setMobileOpen(false)}>
              <ListItemButton
                component={Link}
                to={link}
                onClick={() => setActivemenu(link)}
                sx={{
                  color: activeMenu === link ? '#ffe263' : 'inherit',
                }}
              >
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
      <Link to="https://t.me/memegramAIBot" target="_blank">
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#ffe263',
            '&:hover': {
              backgroundColor: '#ffb703',
            },
            color: '#225093',
            position: 'fixed',
            bottom: 8,
            width: `${drawerWidth - 16}px`,
            marginLeft: '8px',
            textTransform: 'none',
            fontWeight: 700,
          }}
          size="medium"
        >
          Telegram Bot 🤖
        </Button>
      </Link>
    </Box>
  );

  return (
    <Router>
      <Box sx={{ display: 'flex', background: '#' }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: '#3c6dc6',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={memegram} alt="logo" width={40} height="auto" />{' '}
                <img
                  src={memegramTextLogo}
                  alt="logo"
                  width={120}
                  height="auto"
                  style={{ marginLeft: '4px' }}
                />{' '}
              </div>
              <div>
                <Link to="https://t.me/memegramAI" target="_blank">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/color/230/telegram-app--v1.png"
                    alt="telegram-app--v1"
                    style={{ marginRight: '4px' }}
                  />
                </Link>
                <Link to="https://x.com/memegramAI" target="_blank">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/color/230/twitterx--v1.png"
                    alt="twitterx--v1"
                    style={{ marginRight: '4px' }}
                  />
                </Link>
                <Link to="https://memegram.gitbook.io/" target="_blank">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/color/96/documents.png"
                    alt="documents"
                    style={{ marginRight: '4px' }}
                  />
                </Link>
                <Link to="https://memegram.ai" target="_blank">
                  <img
                    width="28"
                    height="28"
                    src="https://img.icons8.com/dusk/64/domain.png"
                    alt="domain"
                    style={{ marginRight: '4px' }}
                  />
                </Link>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#36454F',
                color: 'white',
                borderRight: ' 1px solid white',
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#2c2c2c',
                color: 'white',
                borderRight: ' 1px solid gray',
                overflow: 'hidden',
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            // p: 3,
            // width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Routes>
            <Route
              path="/"
              element={
                <HeaderWrapper
                  header="Edit Blank Templates"
                  subHeader="Add captions on the blank meme templates"
                >
                  <MemeTemplate />
                </HeaderWrapper>
              }
            />
            <Route
              path="/generate-meme"
              element={
                <HeaderWrapper
                  header="Generate AI Meme"
                  subHeader="Use your imagination and generate funny AI memes"
                >
                  <GenerateAiMeme />
                </HeaderWrapper>
              }
            />
            <Route
              path="/caption"
              element={
                <HeaderWrapper
                  header="Add Caption On Your Memes"
                  subHeader="Add up and down captions on any images and gifs"
                >
                  <CaptionImage />
                </HeaderWrapper>
              }
            />
            <Route
              path="/ai-image"
              element={
                <HeaderWrapper
                  header="Generate AI Image"
                  subHeader="Use your imagination and generate AI images"
                >
                  <AiImage />
                </HeaderWrapper>
              }
            />
            <Route
              path="/search"
              element={
                <HeaderWrapper
                  header="Search projects"
                  subHeader="Input telegram id or name of your group to find your generated contents"
                >
                  <Search />
                </HeaderWrapper>
              }
            />
          </Routes>
          <Link to="https://t.me/memegramAIBot" target="_blank">
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#ffe263',
                '&:hover': {
                  backgroundColor: '#ffb703',
                },
                position: 'fixed',
                bottom: 0,
                border: 'none',
                borderRadius: '0',
                width: `100%`,
                textTransform: 'none',
                fontWeight: 700,
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
              size="medium"
            >
              Try Telegram Bot 🤖
            </Button>
          </Link>
        </Box>
      </Box>
    </Router>
  );
};

export default App;

const HeaderWrapper = ({
  header,
  subHeader,
  children,
}: {
  header: React.ReactNode;
  subHeader: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        marginBottom: '16px',
        height: {
          xs: '100% !important',
          sm: 'calc(100vh-64px) !important',
        },
      }}
    >
      <PageHeader headerText={header} subHeaderText={subHeader}></PageHeader>
      {children}
    </Box>
  );
};
