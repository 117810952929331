import { atom } from "recoil";

interface SelectedImageData {
  imageUrl: any;
  upText: string;
  downText: string;
}

export const imagePrompt = atom({
  key: "imagePrompt",
  default: {
    prompt: "",
  },
});

export const memePrompt = atom({
  key: "memePrompt",
  default: {
    prompt: "",
  },
});

export const captionSelectedImage = atom<SelectedImageData>({
  key: "captionSelectedImage",
  default: {
    imageUrl: "",
    upText: "",
    downText: "",
  },
});

export const boxValuesState = atom({
  key: "boxValuesState",
  default: {
    imageId: ''
  },
});
